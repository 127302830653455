import qs from 'qs';
import { isProductWebUrl } from './validationUtil';

/*****************************
 * URL 처리 관련 유틸
 ******************************/

/**
 * 쿼리 스트링 존재여부 체크
 * @param webUrl
 * @returns
 */
export const isExistQueryString = (webUrl: string) => {
  try {
    return !!new URL(webUrl).search;
  } catch (e) {
    return false;
  }
};

/**
 * https://가 들어가 있는 full url -> pathname 추출
 * @param webUrl
 * @returns
 */
export const getPathname = (webUrl: string) => {
  try {
    return new URL(webUrl).pathname;
  } catch (e) {
    return '';
  }
};

/**
 * 다이렉트상품 웹 URL에 한하여 쿼리 스트링을 붙여줍니다.(생년월일 등 붙이기용)
 * 상품 URL이 아니면 입력 URL을 그대로 리턴해줍니다.
 */
export const getAddingQueryStringUrlForProductWeb = (url: string, param?: Record<string, string> | null) => {
  const queryParam = qs.stringify(param);

  return isProductWebUrl(url) && queryParam ? `${url}${isExistQueryString(url) ? '&' : '?'}${queryParam}` : url;
};

/**
 * 최초에 인입됐던 쿼리 스트링을 붙인 URL을 리턴(상품웹을 위한 함수)
 * @param webUrl
 */
export const getAddingInitQueryStringUrl = (webUrl: string) => {
  const isExistQuery = isExistQueryString(webUrl);
  const initQuery = (sessionStorage.getItem('init_query') ?? '').slice(1);

  return `${webUrl}${isExistQuery ? '&' : `${initQuery ? '?' : ''}`}${initQuery}`;
};

/**
 * query 객체를 string으로 변환해줍니다.
 */
export const getWebQueryStringUrl = (query?: Record<string, string | string[] | undefined> | null) => {
  const queryParam = qs.stringify(query);

  return queryParam ? `${queryParam}` : ``;
};
