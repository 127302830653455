import { useEffect } from 'react';
import { useNextPathname } from '@reactjs/hooks/_shared/useNextPathname';
import { useLoginStatusModalStore } from '@hanwhalife/shared-store';

/**
 * Login status Modal Hook
 * path가 바뀌면 초기화합니다.
 * @returns
 */
export const useLoginStatusModal = () => {
  const pathname = useNextPathname();
  const { initLoginStatusModalInfo, loginStatusModalInfo, setLoginStatusModalInfo } = useLoginStatusModalStore();

  useEffect(() => {
    initLoginStatusModalInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return { initLoginStatusModalInfo, loginStatusModalInfo, setLoginStatusModalInfo };
};
