import { create } from '@hanwhalife/shared-libs/zustand';

/** 실행전, 진행중, 단건 처리됨, 완료 */
type ProcessStatusType = 'before' | 'completed';

interface IPostLoginProcessInfo {
  /** 로그인 후 처리상태 */
  postLoginProcessStatus: ProcessStatusType;

  postLoginMeData?: unknown;

  postLoginSource?: 'login';

  /** 로그인 후 이동할 url이 있다면 이동 */
  redirectUrl?: string;

  /** 휴대폰불일치여부 처리상태 */
  phoneNumberMatchStatus: ProcessStatusType;
}
/** 초기 값 */
const initialState: IPostLoginProcessInfo = {
  postLoginProcessStatus: 'before',
  phoneNumberMatchStatus: 'before'
};

interface IPostLoginProcessInfoState {
  postLoginProcessInfo: IPostLoginProcessInfo;
  setPostLoginProcessInfo: (postLoginProcessInfo: Partial<IPostLoginProcessInfo>) => void;
  initPostLoginProcessInfo: () => void;
}

/**
 * 로그인 후 처리 스토어
 */
export const usePostLoginProcessInfo = create<IPostLoginProcessInfoState>((set) => ({
  postLoginProcessInfo: initialState,
  setPostLoginProcessInfo: (postLoginProcessInfo) => {
    set((state) => ({
      postLoginProcessInfo: { ...state.postLoginProcessInfo, ...postLoginProcessInfo }
    }));
  },
  initPostLoginProcessInfo() {
    set(() => ({ postLoginProcessInfo: initialState }));
  }
}));
