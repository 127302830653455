import { UnAuthorizedModal } from './important';

/**
 * 선언형 모달들 집합
 * @returns
 */
export const DeclarativeGlobalModals = () => {
  return (
    <>
      <UnAuthorizedModal />
    </>
  );
};
