import { DeclarativeGlobalModals } from './modals';

/**
 * 선언형 UI 모음집(비즈니스 로직들이 들어간 컴포넌트들)
 * @returns
 */
export const DeclarativeGlobalUI = () => {
  return (
    <>
      <DeclarativeGlobalModals />
    </>
  );
};
