import replace from 'lodash/replace';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { convertDateFormat } from './stringUtil';

/*****************************
 * 날짜 관련 유틸
 ******************************/

dayjs.extend(weekOfYear);
dayjs.locale('ko');

/**
 *
 * @param year
 * @param month
 * @returns
 */
export function getLastDay(year: number, month: number) {
  switch (month) {
    case 2:
      return year % 4 === 0 ? 29 : 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
}

/**
 * 한달뒤 날짜를 포맷형태로 리턴
 * @param 20200101 or 2020.01.01
 * @param 'YYYY.MM.DD'
 * @returns
 */
export const getOneMonthLater = (value?: string, format?: string): string => {
  const str = value ? value.split('.').join('') : '';
  const date = value
    ? new Date(Number(str.substring(0, 4)), Number(str.substring(4, 6)) - 1, Number(str.substring(6, 8)))
    : new Date();
  try {
    return dayjs(new Date(date.setMonth(date.getMonth() + 1))).format(format || 'YYYY.MM.DD');
  } catch (err) {
    console.warn(err);

    return '';
  }
};

/**
 * 날짜 차이를 구한다. 포맷에 맞지 않으면 Not a Number 리턴
 * @param date1 (yyyymmdd or yyyy.mm.dd or yyyy-mm-dd)
 * @param date2 (yyyymmdd or yyyy.mm.dd or yyyy-mm-dd)
 * @returns date1 - date2
 */
export const getDateDiff = (date1: string, date2: string): number => {
  // 포맷 해제
  const yyyyMMdd1 = replace(date1, /[.|-]/g, '');
  const yyyyMMdd2 = replace(date2, /[.|-]/g, '');

  // dayjs object로 변환
  const diff1 = dayjs(dayjs(yyyyMMdd1).format('YYYY-MM-DD'));
  const diff2 = dayjs(yyyyMMdd2).format('YYYY-MM-DD');

  /** diff1 - diff2: 현재: 0 */
  return diff1.diff(diff2, 'day');
};

/**
 * 입력되어진 생년월일 값이 당일 기준으로 미래시점인 경우 false를 반환합니다. (회원체계 기준 9, 0 일 경우 오류)
 * @param front 주민등록번호 앞 6자리
 * @param back 주민등록번호 뒤 첫자리
 * @returns boolean
 */
export const validateBirthdate = (front: string, back: string) => {
  const year = parseInt(front.slice(0, 2));
  const month = parseInt(front.slice(2, 4));
  const day = parseInt(front.slice(4, 6));
  let birthYear = 0;

  switch (back) {
    case '1':
    case '2':
    case '5':
    case '6':
      birthYear = 1900 + year;
      break;
    case '3':
    case '4':
    case '7':
    case '8':
      birthYear = 2000 + year;
      break;
    case '9':
    case '0':
      // birthYear = 1800 + year;
      return false;
    default:
      return false;
  }

  const birthdate = new Date(birthYear, month - 1, day);
  const currdate = new Date();

  return birthdate < currdate;
};

/**
 * 목표 날짜가 비교 날짜 이후 인지 확인하는 함수 (YYYYMMDD 형식)
 * @param targetDate
 * @param compareDate
 * @returns boolean
 */
export function isDateAfter(targetDate: string, compareDate: string) {
  const targetDateTime = new Date(convertDateFormat(targetDate, 'YYYY-MM-DD')).getTime();
  const compareDateTime = new Date(convertDateFormat(compareDate, 'YYYY-MM-DD')).getTime();

  return targetDateTime > compareDateTime;
}
