import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Spinner } from '@hanwhalife/design-system';
import { AgreeDateControlContext } from '../../components/agreement/context/AgreeDateControlContext';
import { StyledIframeContainer } from '@styled/_shared';

export type ChildList = {
  id: string;
  title: string;
  url: string;
  isChecked: boolean;
  isRequired: boolean;
  isShow: boolean;
  isMustRead: boolean;
  initData: any;
  returnData: any;
  controlComponent: React.JSX.Element;
  mainBtnDisabled: boolean;
  mainBtnText: string;
};

export type TermChildList = {
  title: string;
  childList: Array<ChildList>;
};

const initState = {
  id: '',
  title: '',
  url: ``,
  isChecked: false,
  isRequired: false,
  isShow: true,
  isMustRead: false,
  initData: {},
  returnData: {},
  controlComponent: <></>,
  mainBtnDisabled: false,
  mainBtnText: ''
};

/**
 * 공통약관 커스텀 훅
 * @param terms
 * @returns
 */
export const useCommonTerms = (terms: TermChildList[]) => {
  // 모두동의 Flag
  const [customCheckAllFlag, setCustomCheckAllFlag] = useState(false);

  const [isTermLoading, setIsTermLoading] = useState(false);
  const [termList, setTermList] = useState(terms);
  const [selectedTermData, setSelectedTermData] = useState(initState);

  /** 현재 선택된 약관 상태 초기화 */
  const initiateSelectedTermData = useCallback(() => {
    setSelectedTermData(initState);
  }, [selectedTermData]);

  useEffect(() => {
    const childList: ChildList[] = [];
    Object.values(termList).forEach((terms) => {
      for (const term of terms.childList) {
        if (term.isRequired && term.isShow) {
          childList.push(term);
        }
      }
    });

    childList.every((term) => term.isChecked) ? setCustomCheckAllFlag(true) : setCustomCheckAllFlag(false);
  }, [termList]);

  /** 전체 선택 */
  const handleAllChecks = () => {
    setTermList((curTerms) => {
      const updatedTermsState = [...curTerms];

      Object.values(updatedTermsState).forEach((terms) => {
        for (const term of terms.childList) {
          if (customCheckAllFlag) {
            term.isChecked = false;
          } else {
            if (term.isShow) {
              if (term.isMustRead) {
                setSelectedTermData({
                  id: term.id,
                  title: term.title,
                  url: term.url,
                  isChecked: term.isChecked,
                  isMustRead: term.isMustRead,
                  isRequired: term.isRequired,
                  isShow: term.isShow,
                  initData: term.initData,
                  returnData: term.returnData,
                  controlComponent: term.controlComponent,
                  mainBtnDisabled: term.mainBtnDisabled,
                  mainBtnText: term.mainBtnText
                });

                break;
              } else {
                term.isChecked = !customCheckAllFlag;
              }
            }
          }
        }
      });

      return updatedTermsState;
    });
  };

  const selectTerm = (term: ChildList) => {
    setSelectedTermData({
      id: term.id,
      title: term.title,
      url: term.url,
      isChecked: term.isChecked,
      isMustRead: term.isMustRead,
      isRequired: term.isRequired,
      isShow: term.isShow,
      initData: term.initData,
      returnData: term.returnData,
      controlComponent: term.controlComponent,
      mainBtnDisabled: term.mainBtnDisabled,
      mainBtnText: term.mainBtnText
    });
  };

  const handleSmallCategoryChange = (childId: string, data: object, mainBtnDisabled: boolean) => {
    setTermList((prevTermList) => {
      const updatedTermsState = [...prevTermList];
      let child = initState;

      Object.values(updatedTermsState).forEach((terms) => {
        for (const term of terms.childList) {
          if (term.id === childId) {
            child = term;
          }
        }
      });

      if (child && child.isShow) {
        // 필수로 노출해야 하는 약관
        if (!child.isChecked) {
          child.isChecked = !child.isChecked;
        }
        if (child.isMustRead) {
          child.isMustRead = !child.isMustRead;
        }
        child.returnData = data;
        child.mainBtnDisabled = mainBtnDisabled;

        return updatedTermsState;
      }

      return prevTermList;
    });
  };

  const selectCheckBox = (id: string) => {
    setTermList((prevTermList) => {
      const updatedTermsState = [...prevTermList];
      let child = initState;

      Object.values(updatedTermsState).forEach((terms) => {
        for (const term of terms.childList) {
          if (term.id === id) {
            child = term;
          }
        }
      });

      if (child && child.isShow) {
        child.isChecked = !child.isChecked;
        child.returnData = child.initData;

        return updatedTermsState;
      }

      return prevTermList;
    });
  };

  const TermsPopup = () => {
    const [mainBtnDisabled, setMainBtnDisabled] = useState(selectedTermData.mainBtnDisabled);
    const [returnData, setAgreeData] = useState(selectedTermData.returnData);
    const [validation, setValidation] = useState(() => () => {
      console.log('default setValidation');

      return true;
    });

    const setAgreeState = (data: object) => {
      setAgreeData(data);
    };

    return (
      <AgreeDateControlContext.Provider value={{ returnData, setValidation, setAgreeState, setMainBtnDisabled }}>
        <Modal
          type="full-popup"
          title=""
          bodyContentsClassName={selectedTermData.url ? 'ps-0 pe-0 pb-0' : ''}
          bodyContents={
            <>
              {selectedTermData.isShow && selectedTermData.url && (
                <StyledIframeContainer>
                  <iframe
                    src={selectedTermData.url}
                    height="100%"
                    width="100%"
                    title={selectedTermData.title}
                    onLoad={() => {
                      setIsTermLoading(false);
                    }}
                  />
                </StyledIframeContainer>
              )}

              {selectedTermData.isShow && selectedTermData.controlComponent}
            </>
          }
          mainBtnDisabled={mainBtnDisabled}
          mainBtnText={selectedTermData.mainBtnText}
          closeIconEnabled={true}
          onMainBtnClick={() => {
            if (validation()) {
              handleSmallCategoryChange(selectedTermData.id, returnData, mainBtnDisabled);

              initiateSelectedTermData();
            }
          }}
          onCloseClick={() => initiateSelectedTermData()}
          showModal={!!selectedTermData.title}
        />
        <Spinner isSpinning={isTermLoading} position="fixed-center" />
      </AgreeDateControlContext.Provider>
    );
  };

  return {
    termList,
    setTermList,
    customCheckAllFlag,
    selectCheckBox,
    handleAllChecks,
    handleSmallCategoryChange,
    isTermLoading,
    setIsTermLoading,
    selectTerm,
    initiateSelectedTermData,
    TermsPopup
  };
};
