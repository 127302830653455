import { Modal } from '@hanwhalife/shared-libs/design-system';

interface IImportantModal {
  portalElementId?: string;
  showUnAuthorized401Modal?: boolean;
  onMainBtnClick?: (e?: React.MouseEvent) => void;
}

/**
 * 우선 순위가 높은 모달을 띄웁니다.
 * @param param0
 * @returns
 */
export const UnAuthorized401Modal = ({
  portalElementId = '_important-modal',
  showUnAuthorized401Modal = false,
  onMainBtnClick
}: IImportantModal) => {
  const importantPortalElement = document?.getElementById(portalElementId);

  return (
    <>
      <Modal
        type="dialog"
        title="로그아웃 안내"
        bodyContents={
          <div className="typography-body2 text-body2">
            다른 기기에서 접속하여 로그아웃 했어요.
            <br />이 화면에서 계속해서 이용하기 원하시는 경우, 다시 로그인 해주세요.
          </div>
        }
        showModal={showUnAuthorized401Modal}
        mainBtnText="확인"
        onMainBtnClick={onMainBtnClick}
        portalContainer={importantPortalElement}
      />
    </>
  );
};
