import { create } from '@hanwhalife/shared-libs/zustand';

interface IImportantModalInfo {
  showUnAuthorized401Modal: boolean;
}
/** 긴급 모달 초기 값 */
const initialState: IImportantModalInfo = {
  showUnAuthorized401Modal: false
};

interface IIImportantModalInfoState {
  /** login 정보, device 정보 */
  importantModalInfo: IImportantModalInfo;
  setImportantModalInfo: (loginInfo: Partial<IImportantModalInfo>) => void;
  initImportantModalInfo: () => void;
}

/**
 * ImportantModal
 */
export const useImportantModalStore = create<IIImportantModalInfoState>((set) => ({
  importantModalInfo: initialState,
  setImportantModalInfo: (importantModalInfo) => {
    set((state) => ({
      importantModalInfo: { ...state.importantModalInfo, ...importantModalInfo }
    }));
  },
  initImportantModalInfo() {
    set(() => ({ importantModalInfo: initialState }));
  }
}));
