import { UnAuthorized401Modal } from '@hanwhalife/reactjs/components/_shared';
import { useImportantModal } from '@hanwhalife/reactjs/hooks/ui/modal';
import { useMounted } from '@hanwhalife/reactjs/hooks';
import { useHlpRouter } from '@reactjs/router';

export const UnAuthorizedModal = () => {
  const mounted = useMounted();
  const router = useHlpRouter();
  const {
    importantModalInfo: { showUnAuthorized401Modal }
  } = useImportantModal();

  return (
    <>
      {mounted && (
        <UnAuthorized401Modal
          showUnAuthorized401Modal={showUnAuthorized401Modal}
          onMainBtnClick={() => {
            // 메인탭으로~
            router.loginPage();
          }}
        />
      )}
    </>
  );
};
