/*****************************
 * Event 관련 유틸
 ******************************/

const createScrollStopListener = ({
  element,
  callback,
  timeout = 100
}: {
  element: HTMLElement | Window;
  callback: () => void;
  timeout?: number;
}) => {
  let removed = false;
  let handle: NodeJS.Timeout | null = null;
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle);
    }
    handle = setTimeout(callback, timeout);
  };
  element.addEventListener('scroll', onScroll);

  return () => {
    if (removed) {
      return;
    }
    removed = true;
    if (handle) {
      clearTimeout(handle);
    }
    element.removeEventListener('scroll', onScroll);
  };
};

const toFit = (cb: () => void) => {
  let tick = false;

  return function trigger() {
    if (tick) {
      return;
    }

    tick = true;

    return requestAnimationFrame(() => {
      tick = false;

      return cb();
    });
  };
};

const getGenderCode = (genderKr: string) => {
  if (genderKr === '남성') {
    return 'M';
  }

  if (genderKr === '여성') {
    return 'F';
  }

  return '';
};
// GA Event Send
const sendEvent_GA = (eventObj: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventObj);
};

export { createScrollStopListener, toFit, getGenderCode, sendEvent_GA };
