import { useEffect } from 'react';
import { useNextPathname } from '@reactjs/hooks/_shared/useNextPathname';
import { useImportantModalStore } from '@hanwhalife/shared-store';

/**
 * 중요 공지 Modal Hook
 * path가 바뀌면 초기화합니다.
 * @returns
 */
export const useImportantModal = () => {
  const pathname = useNextPathname();
  const { initImportantModalInfo, setImportantModalInfo, importantModalInfo } = useImportantModalStore();

  useEffect(() => {
    initImportantModalInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return { initImportantModalInfo, setImportantModalInfo, importantModalInfo };
};
