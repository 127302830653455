/*****************************
 * 유효성 체크 관련 유틸
 ******************************/

import { naturalNumber } from './regexpUtils';

/**
 * 상품 웹 URL인지
 * @param url
 * @returns
 */
export const isProductWebUrl = (url: string) => {
  return isProductNewWebUrl(url) || isAnalysisWebUrl(url) || isProductLegacyWebUrl(url);
};

/**
 * 신규 상품 웹 URL인지
 * @param url
 * @returns
 */
export const isProductNewWebUrl = (url: string) => {
  return url.includes('direct.hanwhalife.com/products');
};

/**
 * 신규 상품 웹 URL인지
 * @param url
 * @returns
 */
export const isAnalysisWebUrl = (url: string) => {
  return url.includes('direct.hanwhalife.com/analysis');
};

/**
 * 레거시 상품 웹(온슈어) URL인지
 * @param url
 * @returns
 */
export const isProductLegacyWebUrl = (url: string) => {
  return url.includes('onsure.co.kr');
};

/**
 * 다이렉트 웹 URL인지
 */
export const isDirectWeb = (url: string) => {
  return !isProductWebUrl(url) && url.includes('direct.hanwhalife.com');
};

/**
 * 홈페이지 모바일웹 URL인지
 */
export const isHomePageMoUrl = (url: string) => {
  return url.includes('m.hanwhalife.com');
};

/**
 * 문자열이 json인지
 */
export const isJson = (str: string) => {
  try {
    const parseString = JSON.parse(JSON.stringify(str));

    if (typeof parseString === 'string') {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log(e);

    return false;
  }
};

/**
 * URL에 파일 확장자가 있는지 검사
 * @param string
 * @returns
 */
export const hasExtension = (string: string) => /\.[0-9a-z]+$/i.test(string);

/**
 * 로그인 페이지 여부
 * @param requestURI
 * @returns
 */
export const isLoginPageUrl = (requestURI: string) => {
  return requestURI.startsWith('/app/login') && hasExtension(requestURI) === false;
};

/**
 * 로그아웃 페이지 여부
 * @param requestURI
 * @returns
 */
export const isLogoutPageUrl = (requestURI: string) => {
  return requestURI.startsWith('/app/logout') && hasExtension(requestURI) === false;
};

/**
 * 회원가입 페이지 여부
 * @param requestURI
 * @returns
 */
export const isSignupPageUrl = (requestURI: string) => {
  return requestURI.startsWith('/app/signup') && hasExtension(requestURI) === false;
};

/**
 * 로컬 페이지 여부
 * @param requestURI
 * @returns
 */
export const isLocalPageUrl = (url: string) => {
  return url.includes('//localhost') || url.includes('//127.0.0.1');
};

/**
 * 페이지 직접 호출 요청 여부
 * @param requestURI
 * @returns
 */
export const isDirectPageUrlRequest = (requestURI: string) => {
  return !requestURI.startsWith('/api') && !hasExtension(requestURI);
};

/**
 * 로그인 필요 페이지 여부
 * @param requestURI
 * @returns
 */
export const isLoginRequiredPage = (requestURI: string) => {
  return isLoginPageUrl(requestURI) === false && requestURI.startsWith('/app/individual');
};

export const isNaturalNumber = (value: string) => {
  return naturalNumber.test(value) && !isNaN(Number(value));
};
