/* eslint-disable turbo/no-undeclared-env-vars */
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_DIRECT_WEB_GA_ID ?? process.env.NEXT_PUBLIC_DIRECT_WEB_GA_ID;

// 센트리
export const SENTRY_HOST = process.env.NEXT_PUBLIC_SENTRY_HOST;
export const SENTRY_ORG = process.env.NEXT_PUBLIC_SENTRY_ORG;

// 센트리 for direct
export const SENTRY_DIRECT_PROJECT = process.env.NEXT_PUBLIC_SENTRY_DIRECT_PROJECT;
export const SENTRY_DIRECT_DSN = process.env.NEXT_PUBLIC_SENTRY_DIRECT_DSN;
export const SENTRY_DIRECT_AUTH_TOKEN = process.env.NEXT_PUBLIC_SENTRY_DIRECT_AUTH_TOKEN;

// 카카오톡
export const KAKAO_APP_KEY = process.env.NEXT_PUBLIC_KAKAO_APP_KEY;

// 카카오톡 메세지 템플릿 ID
export const KAKAO_EVENT_TEMPLATE_ID = process.env.NEXT_PUBLIC_KAKAO_EVENT_TEMPLATE_KEY;
export const KAKAO_INTERGRITY_KEY = process.env.NEXT_PUBLIC_KAKAO_INTERGRITY_KEY;

// 센트리 for int
export const SENTRY_INT_PROJECT = process.env.NEXT_PUBLIC_SENTRY_INT_PROJECT;
export const SENTRY_INT_DSN = process.env.NEXT_PUBLIC_SENTRY_INT_DSN;
export const SENTRY_INT_AUTH_TOKEN = process.env.NEXT_PUBLIC_SENTRY_INT_AUTH_TOKEN;

// 센트리 for test
export const SENTRY_TEST_PROJECT = process.env.NEXT_PUBLIC_SENTRY_TEST_PROJECT;
export const SENTRY_TEST_DSN = process.env.NEXT_PUBLIC_SENTRY_TEST_DSN;
export const SENTRY_TEST_AUTH_TOKEN = process.env.NEXT_PUBLIC_SENTRY_TEST_AUTH_TOKEN;

// msw
export const MSW_ENABLED = process.env.NEXT_PUBLIC_MSW_ENABLED;
