import { useEffect, useCallback } from 'react';
import { bridge } from '@hanwhalife/bridge';
import { getWebViewUserAgent, isHlpWebview, parseData } from '@hanwhalife/shared-utils';
import { useLoginInfo } from '@hanwhalife/shared-store';
import { IAccount, ILoginInfo, CertType, IDeviceInfo } from '@hanwhalife/ts-types';

/**
 * 전역 로그인 데이터 세팅(for webview)
 * @param param0
 * @returns
 */
export const useInitializeLoginInfo = ({ initLoad = true }: { initLoad?: boolean }) => {
  const { setLoginInfo } = useLoginInfo();

  // 로그인 정보 초기화
  const initializeLoginInfo = useCallback(async () => {
    /**
     * 초기 데이터를 불러온다.
     */
    const initRequests = [
      bridge.getDeviceInfo(),
      bridge.loadData({ key: 'certtype' }),
      bridge.loadData({ key: 'account' }),
      bridge.getLoginInfo()
    ];

    const [deviceInfoRes, certTypeRes, accountRes, loginInfoRes] = await Promise.all(initRequests);

    /**
     * 디바이스 정보
     */
    const { resData: deviceData } = deviceInfoRes;
    if (deviceData) {
      const deviceParseData = parseData<IDeviceInfo>(deviceData as string);

      // TODO: 안드로이드 정상화시 원상복귀 필요
      if (deviceParseData.platformName === 'Android') {
        const { resData: androidLatestDeviceId } = await bridge.loadData({ key: 'deviceid' });

        setLoginInfo({
          ...deviceParseData,
          deviceID: androidLatestDeviceId ? (androidLatestDeviceId as string) : deviceParseData.deviceID || ''
        });
      } else {
        setLoginInfo(deviceParseData);
      }
    }

    /**
     * 인증 타입 데이터
     */
    const { resData: certTypeData } = certTypeRes;
    if (certTypeData) {
      setLoginInfo({ certType: certTypeData as CertType });
    }

    /**
     * 계정 정보
     */
    const { resData: accountData } = accountRes;
    if (accountData) {
      const accountParseData = parseData<IAccount>(accountData as string);

      setLoginInfo({ account: accountParseData });

      certTypeData ? setLoginInfo({ isMember: true }) : setLoginInfo({ isMember: false });
    } else {
      setLoginInfo({ isMember: false });
    }

    /**
     * 로그인 정보
     */
    const { resData: loginData } = loginInfoRes;
    if (loginData) {
      const loginInfoParseData = parseData<ILoginInfo>(loginData as string);

      setLoginInfo({
        ...loginInfoParseData,
        isLogined: !!(
          loginInfoParseData.integrationMemberId &&
          loginInfoParseData.accessToken &&
          loginInfoParseData.memberStatus === 'NORM'
        )
      });
    }

    /**
     * 사용자 정보
     */
    setLoginInfo({
      isInitLoginInfo: true,
      loginChannel: 'HLAPP',
      platform: getWebViewUserAgent(),
      isNative: isHlpWebview()
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initLoad) {
      initializeLoginInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initializeLoginInfo };
};
