import { useRouter as usePageRouter } from 'next/router';
import { httpRegex } from '@hanwhalife/shared-utils';
import { isPc, isHlpWebview } from '@hanwhalife/shared-utils/bomUtil';
import { isProductWebUrl, isDirectWeb, isLocalPageUrl } from '@hanwhalife/shared-utils/validationUtil';
import { getAddingInitQueryStringUrl } from '@hanwhalife/shared-utils/urlUtil';
import { IMoveWebPageArgs, IWebPushOptions } from './routers.types';
import { bridge } from '@hanwhalife/bridge';
import { YesNo } from '@hanwhalife/ts-types';
import { BLOCK_LIST } from './routing-info';

/**
 * 이동 처리는 모두 여기서 처리
 * @returns
 */
export const useHlpRouter = () => {
  const pageRouter = usePageRouter();

  class HlpRouter {}

  const customFunctionMap = {
    /**
     * full url을 입력 했을때 이동처리
     * 같은 도메인이면 push, 상품이면 href, 이외에는 새창
     * @param Full URL
     * @returns
     */
    moveLink(url: string) {
      // 이동할 URL
      const targetUrl = this.getLink(url) as string;

      // 상품 다이렉트 - 그대로 url 이동
      if (isProductWebUrl(url)) {
        window.location.href = targetUrl;

        return;
      }

      // 다이렉트 웹 router로 화면 이동(https 없으면 라우터 push)
      if (isDirectWeb(url) || !httpRegex.test(url) || isLocalPageUrl(url)) {
        pageRouter.push(targetUrl);

        return;
      }

      // 나머지 새창
      window.open(targetUrl, '_blank', 'noopener');
    },
    /**
     * https://가 들어가 있는 full url -> 홈페이지, 온슈어, 다이렉트웹 이동처리
     * @param url
     * @returns
     */
    getLink(url: string) {
      // 다이렉트 웹 루트 뒤 리턴
      if (isDirectWeb(url)) {
        return url.split('com')[1] ?? '/';
      }

      // 로컬 환경
      if (isLocalPageUrl(url)) {
        return url.split(location.host)[1] ?? '/';
      }

      // 상품 URL
      if (isProductWebUrl(url)) {
        return getAddingInitQueryStringUrl(url);
      }

      // 나머지 (홈페이지)
      return isPc() ? url.replaceAll('https://m.', 'https://www.').replaceAll('https://qam.', 'https://qa.') : url;
    },

    /**
     * a tag target 리턴
     * @param url
     * @returns
     */
    getTarget(url: string) {
      if (url) {
        // 다이렉트 웹까지 셀프
        return isDirectWeb(url) ? undefined : isProductWebUrl(url) ? '_self' : '_blank';
      } else {
        return '_self';
      }
    },

    /**
     * a tag rel 리턴
     * @param url
     * @returns
     */
    getRel(url: string) {
      return this.getTarget(url) === '_blank' ? 'noopener' : undefined;
    },

    /**
     * a tag title 리턴
     * @param url
     * @returns
     */
    getTitle(url: string) {
      return this.getTarget(url) === '_blank' ? '새창열림' : undefined;
    },

    /**
     * 화면 이동(for Web, Native)
     * @param args
     * @param pushOptions
     */
    moveWebPage(args: IMoveWebPageArgs, pushOptions?: Omit<IWebPushOptions, 'url'>) {
      // 웹이거나 라우터 사용여부가 Y일경우 라우터로 이동
      if (args.path && (!isHlpWebview() || args.routerUseYn === 'Y')) {
        return pageRouter.push(args.path, pushOptions?.as, pushOptions?.options);
      }

      return bridge.moveWebPage({ ...args });
    },

    /**
     * 메인탭 화면 이동(only Native)
     * @param args
     * @param pushOptions
     */
    moveMainTabPage(tab: '0' | '1' | '2') {
      this.moveWebPage({ channelType: 'main', path: tab });
    },

    /**
     * 화면 닫기(for Native)
     * @param args
     */
    closeScreen(args: { reload: YesNo } = { reload: 'N' }) {
      if (isHlpWebview()) {
        return bridge.webClose({ reload: args.reload });
      }

      return window.close();
    },

    /**
     * 뒤로가기(for Web, Native)
     * @param args
     */
    backScreen(args: { reload?: YesNo; byHardware?: boolean } = { reload: 'N', byHardware: false }) {
      const { asPath } = pageRouter;

      if (isHlpWebview()) {
        // 뒤로가기 막아야 하는 페이지에서는 return
        if (args.byHardware && BLOCK_LIST.some((item) => asPath.includes(item))) {
          console.log(`BLOCK---LIST: ${asPath}`);

          return;
        }

        return bridge.webBack({ reload: args.reload as YesNo });
      }

      return pageRouter.back();
    },
    /**
     * 로그인페이지 이동(for Web, Native)
     * @param args
     */
    loginPage() {
      if (isHlpWebview()) {
        return bridge.loginPage();
      }

      // TODO: 로그인 페이지 이동
      //return pageRouter.push('/login');
      return;
    },
    /**
     * 회원가입페이지 이동(for Web, Native)
     * @param args
     */
    joinPage() {
      if (isHlpWebview()) {
        return bridge.joinPage();
      }

      // TODO: 회원가입 페이지 이동
      //return pageRouter.push('/login');
      return;
    }
  };

  Object.assign(HlpRouter.prototype, pageRouter);
  Object.assign(HlpRouter.prototype, customFunctionMap);

  return new HlpRouter() as typeof pageRouter & typeof customFunctionMap;
};
